/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importing Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  -webkit-user-drag: none;
}

.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background-color: white !important;
  opacity: 0.5 !important;
  transition: opacity 0.3s, background-color 0.3s !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.pagination-below .swiper-pagination-bullet {
  background-color: gray !important;
  opacity: 1 !important;
  transition: opacity 0.3s, background-color 0.3s !important;
}

.pagination-below .swiper-pagination-bullet-active {
  background-color: darkgray !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  color: black !important;
  opacity: 0.5 !important;
  transition: opacity 0.3s, background-color 0.3s !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  font-weight: 900 !important;
}

.BRAND-LIST::-webkit-scrollbar {
  width: 0.25rem;
}

.BRAND-LIST::-webkit-scrollbar-track {
  background-color: transparent;
}

.BRAND-LIST::-webkit-scrollbar-button {
  background-color: transparent;
  height: 0.5rem;
}

.BRAND-LIST::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  border-radius: 1rem;
}

.BRAND-LIST::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.CART-ITEMS::-webkit-scrollbar {
  width: 1rem;
}

.CART-ITEMS::-webkit-scrollbar-track {
  background-color: transparent;
}

.CART-ITEMS::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  background-clip: padding-box;
  border-radius: 1rem;
  border: 6px solid transparent;
}

.CART-ITEMS::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.COUPON-LIST::-webkit-scrollbar {
  width: 0.25rem;
}

.COUPON-LIST::-webkit-scrollbar-track {
  background-color: transparent;
}

.COUPON-LIST::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  border-radius: 1rem;
}

.COUPON-LIST::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.GIFT-CARDS-CONTAINER::-webkit-scrollbar {
  width: 0.35rem;
}

.GIFT-CARDS-CONTAINER::-webkit-scrollbar-track {
  background-color: transparent;
}

.GIFT-CARDS-CONTAINER::-webkit-scrollbar-button {
  background-color: transparent;
  height: 0;
}

.GIFT-CARDS-CONTAINER::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.492);
  border-radius: 1rem;
}

.GIFT-CARDS-CONTAINER::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.rounded-up {
  border-radius: 50% 50% 5% 5% / 70% 70% 30% 30%;
}

.rounded-down {
  border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}
